import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData} from "framework/src/Utilities";
export interface IUserData{
  dateOfBirth: string;
  name: string;
  phoneNumber: string;
  email: string;
}
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface ResponseJson{
  errors?: Array<string | {}>;
  messages?: [{}]| [string];
  data?: {email:string,phone:string}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  checked?:boolean;
  renderBlock: (route: string) => void
  // Customizable Area End
}

interface S {  
  // Customizable Area Start
  isLoading: boolean;
  renderAccountSettingsType: string;
  passwordVerified: boolean;
  selectedType: string;
  userData: IUserData;
  editUserType: string;
  editUserTypeValue: string;
  messageType: string;
  message: string;
  logoutModal: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  showNewPassword: boolean;
  pasError: string;
  deactivatePass: string;
  updateError: string;
  userInfo:{email:string,phone:string};
  userAccounts:{id:number,title:string,clicked:boolean}[];
  otpAccount:string;
  step:number;
  token:string;
  otpCode:string;
  downloadStatus:string;
  isOpen:boolean;
  apiError:string;
  otpModal:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validatePasswordId:string ="";
  getAccountDataId: string ="";
  deactivateApiId: string = "";
  updateNewPswrdId: string = "";
  updateUserNameId: string = "";
  findAccountApiId:string="";
  sendOtpApiId:string="";
  otpApiCallId:string="";
  resendOtpId:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      isOpen:false,
      apiError:'',
      downloadStatus:'open',
      passwordVerified: false,
      selectedType: "default",
      editUserType: "",
      editUserTypeValue: "",
      messageType: "",
      message: "",
      logoutModal: false,
      showPassword: false,
      showConfirmPassword: false,
      showNewPassword: false,
      pasError: "",
      deactivatePass: "",
      otpCode:'',
      otpModal:false,
      otpAccount:'',
      step:0,
      token:'',
      updateError: "",
      userData: {} as IUserData,
      userInfo:{email:'',phone:''},
      userAccounts:[],
      renderAccountSettingsType: "default",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.validatePasswordId) {
          this.validateAPiResponse(responseJson)
        }
        if(apiRequestCallId === this.getAccountDataId){
          if(responseJson.data){
            const data = responseJson.data.attributes
            this.setState({
              userData: {
                name: data.user_name,
                dateOfBirth: data.date_of_birth,
                phoneNumber: data.full_phone_number,
                email: data.email
              },
              isLoading: false
            })
          }
        }
        if(apiRequestCallId === this.deactivateApiId){
          this.onDeactivateSuc(responseJson)
        }
        if(apiRequestCallId === this.updateNewPswrdId){
          this.onUpdatePswdResponse(responseJson)
        }
        if(apiRequestCallId === this.updateUserNameId){
          this.onUpdateUSerNameApiSuc(responseJson)
        }

        this.verifyFindAccountApi(message)
        this.verifySendOtpapi(message)
        this.verifyOtpApi(message) 
        this.verifyResendApi(message)
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }
  }

  verifyResendApi=async(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJsonResend = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJsonResend && !responseJsonResend.errors) {
        if(apiRequestCallId === this.resendOtpId){
          this.setState({token:responseJsonResend.meta.token});
        }
      }
    }

  }
  
  verifyFindAccountApi=async(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (responseJson && !responseJson.errors) {
        if(apiRequestCallId === this.findAccountApiId){
          this.searchUserApi(responseJson);
        };
      } else {
        this.setState({ apiError: responseJson.errors[0] });
      }
    }

  }

  verifySendOtpapi=async(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if(apiRequestCallId === this.sendOtpApiId){
          this.getOtpApi(responseJson);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] });
      }
    }
  }

  verifyOtpApi=async(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if(apiRequestCallId === this.otpApiCallId){
          this.setState({ apiError: "" })
          this.submitOtpApi(responseJson);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] });
      }
    }
  }
  onUpdateUSerNameApiSuc = (responseJson: ResponseJson) => {
    if(responseJson.data){
      this.setMessage("suc", "Your username has been updated successfully");
      setTimeout(() => {
        this.setState({
          messageType: "",
          message: ""
        })
        this.onClickBackButton();
      },2000)
    }
    if(responseJson.errors && typeof responseJson.errors[0] === 'string'){
      this.setState({
        updateError: responseJson.errors[0]
      })
    }
  }
  onUpdatePswdResponse = (responseJson: ResponseJson) => {
    if(responseJson.errors && typeof responseJson.errors[0] === 'string'){
      this.setState({
        pasError:  responseJson.errors[0]
      });
    }
    if(responseJson.data){
      this.setMessage("suc", "Your Password has been updated successfully");
      setTimeout(() => {
        this.setState({
          messageType: "",
          message: ""
        })
        this.onClickBackButton();
      },2000)
     
    }
  }

  onDeactivateSuc = (responseJson: ResponseJson) => {
    if(responseJson.messages && typeof responseJson.messages[0] === 'string'){
      setTimeout(() => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo");
        
      },200)
     
    }
  }
  onVerifyPasswordChang = () => {
    this.setState({
      pasError: "" 
    });
  }
  validateAPiResponse = (responseJson: ResponseJson) => {
    if(responseJson.data!==undefined){
      this.setState({userInfo:{email:responseJson.data.email,phone:responseJson.data.phone}},()=>this.searchAccount())
    }
    if(responseJson.errors){
      this.setState({
        pasError: "Incorrect Password" 
      });
    }else if(responseJson.messages && responseJson.messages[0] === "Verified"){
      
      if(this.state.renderAccountSettingsType == "verifyPassword"){
        const slectedType = this.state.selectedType
        this.setState({
          renderAccountSettingsType: slectedType,
        })
      }else if(this.state.renderAccountSettingsType == "deactivateAccount"){
        this.setState({
          logoutModal: true
        })
      }
    }
  }

  searchUserApi=(responseJson:{messages:string[],errors:string})=>{
    if (responseJson && !responseJson.errors) {
      const accountArray=responseJson.messages!==undefined && responseJson.messages.map((each)=>({id:Date.now(),title:each,clicked:false}))
      this.setState({userAccounts:accountArray as []})
      }  
  }

  getOtpApi=(responseJson:{meta:{token:string},errors:''})=>{
    if (responseJson && !responseJson.errors) {
     this.setState({step:1,token:responseJson.meta.token})
    }
  }

  submitOtpApi=(responseJson:{errors:''})=>{
    if (responseJson && !responseJson.errors) {
      this.setState({step:2})
     }
  }

  searchAccount=async()=>{
    const contact=await getStorageData('contacts') 
    if(contact!==null){
      const parsedData=JSON.parse(contact)
      if(parsedData.email!=='' || parsedData.phone!==''){
        this.setState({userInfo:JSON.parse(contact)})
      } else{
        this.setState({userInfo:this.state.userInfo},()=>setStorageData('contacts',JSON.stringify(this.state.userInfo)))
      } 
    }
    else{
      setStorageData('contacts',JSON.stringify(this.state.userInfo))
    } 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let httpBody={}
    if(this.state.userInfo.email!=='' && this.state.userInfo.phone===''){
      httpBody = {
        data: {         
          attributes: {
            email: this.state.userInfo.email
          },
        },
        }   
    }
    else if(this.state.userInfo.phone!=='' && this.state.userInfo.email==null){
      httpBody = {
        data: {         
          attributes: {
            full_phone_number:this.state.userInfo.phone
          },
        },
        }  
    }
    else{
      httpBody = {
        data: {         
          attributes: {
            full_phone_number:this.state.userInfo.phone,
            email: this.state.userInfo.email
          },
        },
        }  
    }
    const getMssage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.findAccountApiId = getMssage.messageId;

    getMssage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.findAccountEndpoint
    );

    getMssage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMssage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    getMssage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getMssage.id, getMssage); 
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setMessage = (type:string, msg:string) => {
    this.setState({
      messageType: type,
      message: msg
    })
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.renderAccountSettingsType !== prevState.renderAccountSettingsType) {
      if (this.state.renderAccountSettingsType === "accountInformation") {
         
        this.setState({
          isLoading: true
        })
        this.getAccountData()
      }
    }
  }

  getAccountData = () => {
    this.getAccountDataId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "account_block/accounts/logged_user",
    });
  }

  validatePassword = (values: {password: string}) => {
    if (!values?.password){
      this.setState({
        pasError: "Please Enter password"
      })
      return
    }
    this.validatePasswordId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.validatePasswordApi,
      body: {
        password:  values?.password
      }
    });
    if(this.state.renderAccountSettingsType === "deactivateAccount"){
      this.setState({
        deactivatePass: values?.password
      })
    }
  }
  onClickVisiblePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  onClickConfirmVisible = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  }
  onClickNewPasswordVisible = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword
    })
  }
  onCloseLogout = () => {
    this.setState({
      logoutModal:false
    })
  }
  onLogout = () => {
    if(this.state.renderAccountSettingsType === "accountInformation"){
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
      this.props.renderBlock("Home");
      this.onCloseLogout()
    }else{
      this.setState({        
        logoutModal:false
      })
      const storedData=localStorage.getItem("selectedUser")
      const userID=storedData ? JSON.parse(storedData).user_id : null
      if(userID){
      this.deactivateApiId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiDeleteMethod,
        endPoint: `account_block/accounts/${userID}`,
        body: {
          password: this.state.deactivatePass
        }
      });
      this.props.renderBlock("AccountDeactivated");
    }
    }

  }
  renderBackButtonTitle = () => {
    switch (this.state.renderAccountSettingsType) {
      case "verifyPassword":
        return "Verify Your Password";
      case "accountInformation":
        return "Account Information";
      case "deactivateAccount":
        return "Confirm your password";
      case "changePassword":
        return "Change Password"
      case "download":
      return "Verify its you"  
    }     
  }
  onClickBackButton = async () => {
    let backToType = "default";
    switch (this.state.renderAccountSettingsType) {
      case "verifyPassword":
        // No change needed, as `backToType` is already "default"
        break;
  
      case "userEditForm":
        backToType = "accountInformation";
        break;
  
      default:
        break;
    }
  
    this.setState({
      messageType: "",
      message: "",
      pasError: "",
      renderAccountSettingsType: backToType,
      updateError: ""
    });
  };

  changeAccountSettingsRenderType = (type: string) => {
    this.setState({
      renderAccountSettingsType: type,
      selectedType: type,
    },()=>{
      this.getUserAccounts()
    })
  }

  getUserAccounts=()=>{
    if(this.state.selectedType==='download'){
      this.searchAccount()
    }
  }

  updateNewPswrd = (values: { current_password:string, new_password:string, confirm_password:string}) => {
    this.updateNewPswrdId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPatchMethod,
      endPoint: "account_block/accounts/change_password",
      body: values
    });
  }
  redirectToEditForm = (editType: string, value: string) => {
    this.setState({
      renderAccountSettingsType: "userEditForm",
      editUserType: editType,
      editUserTypeValue: value
    })
  }

  formatDate=(date:string)=>{
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }

  updateUserName = (val: string) => {
    if(val){
      this.updateUserNameId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPatchMethod,
        endPoint: "account_block/update_profile",
        body: {
          user_name: `@${val}`
        }
      });
    }else{
      this.setState({
        updateError: "User Name shouldnot be Empty"
      })
    }
  }

  updateErrorMsg = (msg: string) => {
    this.setState({
      updateError: msg
    })
  }
 
  toggleRadioBtn=(values:{id:number,title:string,clicked:boolean})=>{
  let result=this.state.userAccounts.map((value)=>{
    if(value.id===values.id){
   return {...value,clicked:!value.clicked}
    }else{
      return {...value,clicked:false} 
    }
  })
  this.setState({userAccounts:result},()=>{
    const activeUser=this.state.userAccounts.filter((account)=>account.clicked===true)
    const user=activeUser[0]?.title
    this.setState({otpAccount:user})
  })
  }

  sendOtp=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    let httpBody = this.state.otpAccount.includes('Send an email') ? {
      
  data: {
    attributes: {
      email: this.state.userInfo.email,
      sms_otp: false,
      email_otp: true
    }
  }
    } : {
      
  data: {
    attributes: {
      full_phone_number:this.state.userInfo.phone,
      sms_otp: true,
      email_otp: false
    }
  }
    }
    
    
    const getMssage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOtpApiId = getMssage.messageId;

    getMssage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpEndpoint
    );

    getMssage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMssage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    getMssage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getMssage.id, getMssage); 

  }
  handleChangeOtp=(event:{target:{value:string}})=>{
  this.setState({otpCode:event.target.value})
  }
  handleSendOtp=()=>{
    if (this.state.otpCode !== "") {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const sendData = {
        otp_code: this.state.otpCode,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.otpApiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(sendData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } 
  }
  handleRequestArchive=()=>{
    this.setState({downloadStatus:'loading'})
    setTimeout(()=>{
      this.setState({downloadStatus:'loaded'})
    },2000)
  }

  handleDownloadArchive=()=>{
    this.setState({downloadStatus:'open',step:3})
  }
  handleArchiveData=()=>{
   this.setState({isOpen:true})
  }

  handleRemoveModal=()=>{
    this.setState({isOpen:false})
  }
  cancelDownload=()=>{
    this.setState({isOpen:false})
  }
  downloadData=()=>{
    this.setState({isOpen:false})
  }
  onClickBackButtonOtp=()=>{
    this.setState({step:0})
  }

  onClickBackButtonArchive=()=>{
    this.setState({step:1})
  }

  onClickBackButtonLoad=()=>{
   this.setState({downloadStatus:'open'}) 
  }

  onClickBackButtonSize=()=>{
    this.setState({step:2,downloadStatus:'loaded'}) 
  }

  openOtpModal=()=>{
  this.setState({otpModal:true})
  }

  handleClickClose=()=>{
    this.setState({otpModal:false})
  }

  resendOtp=()=>{
    const resendOtp = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    
    const attributes=this.state.otpAccount.includes('Send an email') ? {email:this.state.userInfo.email}:{full_phone_number:this.state.userInfo.phone}

    const body = {
      data: {
        attributes
      }
    };


    this.resendOtpId = resendOtp.messageId;
    resendOtp.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpEndpoint
    );
    resendOtp.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    resendOtp.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    resendOtp.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(resendOtp.id, resendOtp);
    this.handleClickClose()
  }

  // Customizable Area End
}
